import React from "react";
import { Icon } from "@iconify/react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

import ColumnVisibilityMenu from "./ColumnVisibilityMenu";
import PdfComponent from "../../PDF";
import ReportPdf from "../../address/ReportPdf";
import SocietyMatch from "../../address/SocietyMatch";
const MemberSearchPannel = (props) => {
  const {
    _memberFilters,
    setMemberFilters,
    table,
    setIsAddMemberModalOpen,
    downloadProfilePhotos,
    downloadCsvFile,
    isDownloadOption,
    setDownloadType,
    headers,
    generateQueryParams,
    convertDataInFormat,
    setShowEditField
  } = props;
  return (
    <>
      <div className="border-b border-stone-200 py-1.5 flex items-center justify-between w-full">
        <div className="flex justify-center items-center">
          <div className="w-56 relative">
            <input
              id="search-members"
              type="text"
              placeholder="Search Member"
              autoComplete="off"
              className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
              value={_memberFilters?.searchTerm}
              onChange={(e) => {
                setMemberFilters("searchTerm", e.target.value);
              }}
            />
            <Icon
              icon="iconamoon:search-light"
              className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
            />
          </div>
          <div className="ml-2 pt-0">
            <input
              placeholder="Search SMVNo"
              className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
              value={_memberFilters?.SMVNo}
              onChange={(e) => {
                setMemberFilters("SMVNo", e.target.value);
              }}
            />
          </div>
          <div className="ml-2 pt-0">
            <Menu>
              <MenuHandler className="bg-primary text-sm text-white h-9 w-full flex items-center">
                <Button> Edit</Button>
              </MenuHandler>
              <MenuList>
                <MenuItem onClick={() => setShowEditField("name")}>Name</MenuItem>
                <MenuItem onClick={() => setShowEditField("address")}>Address</MenuItem>
                <MenuItem onClick={() => setShowEditField("village")}>Native Village</MenuItem>
                <hr className="my-3" />
                <MenuItem onClick={() => setShowEditField("occupation")}>Occupation</MenuItem>
                <MenuItem onClick={() => setShowEditField("education")}>Education</MenuItem>
                <hr className="my-3" />
                {/* <MenuItem onClick={() => setShowEditField("groupNo")}>Group no.</MenuItem> */}
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className="flex gap-2">
          {isDownloadOption && (
            <div className="flex items-center gap-2">
              <button
                className="inline-flex items-center bg-[#1D6F42] border border-[#1D6F42] text-white p-2 rounded-lg"
                onClick={() => downloadCsvFile("XLSX")}
              >
                <Icon
                  icon="ri:file-excel-2-line"
                  className="inline mr-1 text-xl"
                />
                Excel
              </button>
              <PdfComponent
                headers={headers}
                _memberFilters={_memberFilters}
                generateQueryParams={generateQueryParams}
                convertDataInFormat={convertDataInFormat}
              ></PdfComponent>
              <div className="py-1 flex items-center">
                <button
                  className="inline-flex items-center bg-primary border border-primary text-white text-sm px-2 py-2 rounded-lg"
                  onClick={() => downloadProfilePhotos()}
                >
                  Download Profile Photos
                </button>
              </div>
            </div>
          )}
          <div className="py-1 flex items-center gap-3">
            <ReportPdf lastAPIParams={_memberFilters} />
            <SocietyMatch lastAPIParams={_memberFilters} />
            <ColumnVisibilityMenu table={table} />
            {setIsAddMemberModalOpen && (
              <button
                className="inline-flex items-center bg-primary text-white text-sm px-2 py-1.5 rounded-md min-h-[38px]"
                onClick={() => setIsAddMemberModalOpen(true)}
              >
                <Icon icon="ic:baseline-plus" className="inline mr-1" />
                Add New Member
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberSearchPannel;
